<template>
  <td class="pl-0">
    <span v-if="paymentMethod.brand">
      <strong>{{ paymentMethod.brand }}</strong> (...{{ paymentMethod.last4 }})
      <div class="text-xs sm:text-md">{{ paymentMethod.exp_month }} - {{ paymentMethod.exp_year }}</div>
    </span>
    <span v-else>
      Noch keine Informationen zu diesem Zahlungsmittel von Stripe erhalten
    </span>
  </td>
</template>
<script>
  export default {
    name: 'StripePaymentMethodInfo',
    props: {
      paymentMethod: {},
    },
  };
</script>
