<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('sharedComponents.cart.cart') }}</p>
    </template>

    <div class="panel">
      <!-- Warenkorb Items -->
      <div class="flex flex-col md:flex-row mt-4">
        <div class="flex-1 flex-col p-4">
          <div v-if="order.paid" class="border rounded p-4 mb-4">
            <div class="font-semibold text-lg mb-3">{{ $t('sharedComponents.cart.thankYou') }}</div>
            <div v-if="$route.meta.area !== 'Company'">{{ $t('sharedComponents.cart.orderSuccessfull') }}</div>
            <div v-else-if="order.paid === 'invoice'" v-html="$t('sharedComponents.cart.invoiceInfo')" />
            <div v-else>{{ $t('sharedComponents.cart.orderSuccessfullInformationRepayment') }}</div>
          </div>
          <!-- Warenkorb-Übersicht -->
          <div class="border rounded p-4">
            <div v-if="order.paid" class="font-semibold text-lg mb-3">{{ $t('sharedComponents.cart.yourOrder') }}</div>
            <div v-else class="font-semibold text-lg mb-3">{{ $t('sharedComponents.cart.yourCart') }}</div>
            <div v-if="bulkOrderDate" class=" font-semibold mb-2">{{ $t('sharedComponents.cart.bulkOrderFrom', { bulkOrderDate: bulkOrderDate }) }}</div>
            <div v-if="orderProducts.length === 0">{{ $t('sharedComponents.cart.emptyCart') }}</div>
            <div v-for="product in orderProducts"
                 v-else
                 :key="product.shop_product_id"
                 class="border-t mb-4 pt-4"
            >
              <div class="flex justify-between">
                <div>
                  <div class="font-semibold text-lg">{{ name(product.shop_product_id) }}</div>
                  <div class="text-gray-500">{{ description(product.shop_product_id) }}</div>
                </div>
                <div class="text-right">
                  <div class="font-bold text-xl">CHF {{ (product.price * product.amount).toFixed(2) }}</div>
                  <div class="text-gray-500 text-xs"><span v-if="isCompanyArea">{{ $t('general.vatExcluded') }}</span><span v-else>{{ $t('general.vatIncluded') }}</span></div>
                </div>
              </div>
              <div class="flex justify-between items-center mt-3">
                <div class="flex">
                  <div class="mr-8">
                    <div class="font-bold text-lg">{{ product.amount }}</div>
                    <div class="text-gray-500 text-xs">{{ $t('general.unit') }}</div>
                  </div>
                  <div>
                    <div class="font-bold text-lg">{{ product.price }}</div>
                    <div class="text-gray-500 text-xs">{{ $t('general.perUnit') }}</div>
                  </div>
                </div>
                <div v-if="!order.paid" class="flex flex-col items-end">
                  <button class="text-red-600 hover:text-red-800" @click="removeFromCart(product)">{{ $t('general.remove') }}</button>
                  <router-link :to="{ name: $route.meta.area + 'Shop' }">
                    <button class="text-red-600 hover:text-red-800">{{ $t('general.change') }}</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div v-if="orderProducts.length > 0" class="w-full flex flex-col md:flex-row pt-4">
            <div class="border rounded p-4 w-full md:w-1/2 mr-2" :class="{ 'border-red-600':(error === 'delivery')}">
              <div class="font-semibold text-lg mb-3" :class="{ 'text-red-600':(error === 'delivery')}">
                {{ formdata.delivery_type && area === 'Customer' ? $t('sharedComponents.cart.invoiceAddress') : $t('sharedComponents.cart.deliveryAdress') }}
              </div>
              <div v-if="formdata.paid" class="mt-3 text-base">
                <span v-if="formdata.delivery_type === 'curbside'">{{ $t('sharedComponents.cart.curbsideDelivery') }}<br></span>
<!--                <span v-if="formdata.delivery_type === 'appartement'">{{ $t('sharedComponents.cart.flatDelivery') }}<br></span>-->
                <span v-if="formdata.delivery_type === 'pickup'">{{ $t('sharedComponents.cart.pickUpmyself') }}<br></span>
                <br>
                {{ formdata.name }} {{ formdata.surname }}<br>
                {{ formdata.address }}<br>
                {{ formdata.postcode }} {{ formdata.city }}
              </div>
              <div v-else>
                <div v-if="area !== 'Company'" class="relative flex w-full showField">
                  <input v-model="formdata.name"
                         type="text"
                         class="mainform-color mainform-input"
                         placeholder="Name"
                         @focusout="storeFormData()"
                  >
                </div>
                <div v-if="area !== 'Company'" class="relative flex w-full showField">
                  <input v-model="formdata.surname"
                         type="text"
                         class="mainform-color mainform-input"
                         placeholder="Vorname"
                         @focusout="storeFormData()"
                  >
                </div>
                <div class="relative flex w-full showField">
                  <input v-model="formdata.address"
                         type="text"
                         class="mainform-color mainform-input"
                         placeholder="Adresse"
                         @focusout="storeFormData()"
                  >
                </div>
                <zip-city-static-field v-if="postcodes.length > 0"
                                       v-model:formdata="formdata"
                                       :postcodes="postcodes"
                                       :field="field"
                                       :option-fields="[]"
                                       mode="noForm"
                                       :validation="{}"
                                       :is-visible="true"
                                       :validation-sign="false"
                                       @focusout="storeFormData()"
                />
                <div v-if="area !== 'Company'" class="relative flex w-full showField">
                  <input v-model="formdata.email"
                         type="text"
                         class="mainform-color mainform-input"
                         placeholder="Email"
                         @focusout="storeFormData()"
                  >
                </div>
                <div v-if="$route.meta.area === 'Customer' || $route.meta.area === 'Public'">
                  <label class="inline-flex items-center mt-3 pl-2">
                    <input v-model="formdata.delivery_type"
                           type="radio"
                           class="form-radio h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
                           value="curbside"
                           @focusout="storeFormData()"
                    >
                    <span class="ml-1 mr-3">{{ $t('sharedComponents.cart.curbsideDelivery') }}</span>
                  </label>
<!--                  <label v-if="companyProfile" class="inline-flex items-center mt-3 pl-2">-->
<!--                    <input v-model="formdata.delivery_type"-->
<!--                           type="radio"-->
<!--                           class="form-radio h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"-->
<!--                           value="appartement"-->
<!--                           @focusout="storeFormData()"-->
<!--                    >-->
<!--                    <span class="ml-1 mr-3">{{ $t('sharedComponents.cart.flatDelivery') }}</span>-->
<!--                  </label>-->
<!--                  <label class="inline-flex items-center mt-3 pl-2">-->
<!--                    <input v-model="formdata.delivery_type"-->
<!--                           type="radio"-->
<!--                           class="form-radio h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"-->
<!--                           value="pickup"-->
<!--                           @focusout="storeFormData()"-->
<!--                    >-->
<!--                    <span class="ml-1 mr-3">{{ $t('sharedComponents.cart.pickUpmyself') }}</span>-->
<!--                  </label>-->

                  <div v-if="area !== 'Company' && (formdata.delivery_type === 'curbside' || formdata.delivery_type === 'appartement')" class="mt-2 text-gray-600 pl-2">
                    Lieferung innerhalb von 3
                    Werktagen
                  </div>
                </div>
              </div>
            </div>
            <div class="border rounded p-4 w-full md:w-1/2 mt-4 md:mt-0 md:ml-2">
              <div class="font-semibold text-lg mb-3">{{ $t('sharedComponents.cart.paymentMethod') }}</div>
              <fieldset>
                <legend class="sr-only">{{ $t('sharedComponents.cart.paymentMethod') }}</legend>
                <div class="space-y-2">
                  <div v-for="(method, index) in paymentMethods" :key="index" class="relative flex items-start">
                    <div class="flex h-6 items-center">
                      <input :id="method.value"
                             v-model="paymentMethod"
                             type="radio"
                             :value="method.value"
                             :class="[
                               'form-radio',
                               'text-indigo-600',
                               {'text-gray-400 dark:text-gray-500': formdata.paid}
                             ]"
                             :disabled="formdata.paid"
                      >
                    </div>

                    <div class="ml-3 text-sm leading-6">
                      <label class="font-medium text-gray-900">
                        <span class="text-gray-800">{{ method.hasTranslation ? $t(method.labelTranslateKey) : method.label }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div v-if="orderProducts.length > 0 && !order.paid" class="w-full pt-4 ">
            <div class="border rounded p-4 flex flex-col" :class="{ 'border-red-600':(error === 'conditions')}">
              <div class="font-semibold text-lg mb-3" :class="{ 'text-red-600':(error === 'conditions')}">{{ $t('sharedComponents.cart.conditions') }}</div>
              <label v-if="isCompanyArea" class="inline-flex items-center">
                <input v-model="noreturn"
                       type="checkbox"
                       class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
                >
                <span class="ml-1 mr-3">{{ $t('sharedComponents.cart.noRightOfReturn') }}</span>
              </label>
              <label v-if="isCompanyArea" class="inline-flex items-center mt-3">
                <input v-model="percent10"
                       type="checkbox"
                       class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
                >
                <div>
                  <div class="ml-1 mr-3">{{ $t('sharedComponents.cart.10Percent') }}</div>
                  <div class="ml-1 mr-3 text-xs text-gray-700">{{ $t('sharedComponents.cart.10PercentExplained') }}
                  </div>

                </div>
              </label>
              <label v-if="isCompanyArea" class="inline-flex items-center mt-3">
                <input v-model="legalTerms"
                       type="checkbox"
                       class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
                >
                <span class="ml-1 mr-3">{{ $t('sharedComponents.cart.conditionModelAgb') }}</span>
              </label>
              <label v-if="$route.meta.area === 'Customer' || $route.meta.area === 'Public'" class="inline-flex items-center mt-3">
                <input v-model="legalTerms"
                       type="checkbox"
                       class="form-checkbox h-6 w-6 border border-gray-400 rounded bg-white text-yellow-400"
                >
                <span class="ml-1 mr-3">{{ $t('sharedComponents.cart.acceptConditions') }}</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Checkout-Bereich -->
        <div class="w-full md:w-1/3 lg:w-1/4 p-4">
          <div class="border rounded p-4">
            <cart-overview checkout="" :formdata="formdata" @store-order="storeOrder" />
            <div v-if="error" class="text-red-600 text-xs mt-3">
              {{ $t('sharedComponents.cart.missingInformation') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <pick-up-points v-if="area !== 'Company'" />

    <stripe-payment v-if="isCompanyArea"
                    ref="stripePayment"
                    :show="showStripeForm"
                    @update:show="showStripeForm = $event"
                    @success="setOrderPaid(true)"
                    @failed="setPaymentFailed()"
    />

    <postfinance-payment ref="postfinancePayment"
                         :show="showPostfinanceForm"
                         @update:show="showPostfinanceForm = $event"
                         @success="setOrderPaid(true)"
                         @failed="setPaymentFailed()"
    />

    <spinner v-if="showSpinner" style="z-index: 9999;" />
  </layout>
</template>

<script>
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import CartOverview from './CartOverview.vue';
  import { shopMixin } from '../../plugins/mixin';
  import companyApi from '../connections/company';
  import ZipCityStaticField from '../formcreator/fieldComponents/zipCityStaticField.vue';
  import StripePayment from '../companyArea/Accounting/credit/StripePayment.vue';
  import PostfinancePayment from '../companyArea/Accounting/credit/PostfinancePayment.vue';
  import Spinner from '../snippets/Spinner.vue';
  import PickUpPoints from '../publicArea/Shop/PickUpPoints.vue';
  import dayjsHelper from '../../plugins/dayjsHelper';

  export default {
    name: 'Cart',
    components: { PickUpPoints, Spinner, PostfinancePayment, StripePayment, ZipCityStaticField, CartOverview, Layout },
    mixins: [shopMixin],
    data() {
      return {
        defaultPaymentMethodsCustomers: [
          {
            label: 'Kreditkarte / Twint',
            value: 'postfinance',
            hasTranslation: true,
            labelTranslateKey: 'sharedComponents.cart.twint',
          },
          {
            label: 'Vorauskasse per Bank',
            value: 'invoice',
            hasTranslation: true,
            labelTranslateKey: 'sharedComponents.cart.invoice',
          },
        ],
        defaultPaymentMethodsCompanies: [
          {
            label: 'Kreditkarte / Twint',
            value: 'postfinance',
            hasTranslation: true,
            labelTranslateKey: 'sharedComponents.cart.twint',
          },
          {
            label: 'Vorauskasse per Bank',
            value: 'invoice',
            hasTranslation: true,
            labelTranslateKey: 'sharedComponents.cart.invoice',
          },
        ],
        showSpinner: false,
        error: '',
        noreturn: false,
        legalTerms: false,
        percent10: false,
        paymentMethod: 'postfinance',
        formdata: {
          company_profile_id: null,
          postcode_id: '',
          address: '',
          city: '',
          delivery_type: 'curbside',
          delivery_costs: this.$route.meta.area === 'Company' ? 0 : 0,
          email: '',
          vat: 8.1,
          currency: 'CHF',
          payment_method: null,
          uuid: '',
          returnBaseUri: window.location.pathname,
          paid: false,
        },
        field: {
          id: 0,
          class: 'flex w-full',
        },
        showStripeForm: false,
        showPostfinanceForm: false,
      };
    },
    computed: {
      isCompanyArea() {
        return this.$route.meta.area === 'Company';
      },
      area() {
        return this.isCompanyArea ? 'Company' : 'Customer';
      },
      orderProducts() {
        return store.state.shop.orderProducts;
      },
      companyProfile() {
        return store.state.shop.companyProfile;
      },
      products() {
        if (this.isCompanyArea) {
          return store.state.shop.companyProducts;
        } else {
          return store.state.shop.customerProducts;
        }
      },
      order() {
        return store.state.shop.order;
      },
      paymentMethods() {
        if (this.isCompanyArea) {
          return this.defaultPaymentMethodsCompanies;
        } else {
          return this.defaultPaymentMethodsCustomers;
        }

      },
      bulkOrderDate() {
        if (this.isCompanyArea && this.orderProducts.length > 0 && store.state.shop.bulkOrders.length > 0) {
          if(store.state.shop.bulkOrders.find(obj => obj.id === this.orderProducts[0]['shop_bulk_order_id'])) {
            return dayjsHelper.showDateText(store.state.shop.bulkOrders.find(obj => obj.id === this.orderProducts[0]['shop_bulk_order_id']).duedate);
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      postcodes() {
        return store.state.main.postcodes;
      },
    },
    watch: {
      'formdata.delivery_type': function () {
        this.formdata.delivery_costs = this.formdata.delivery_type === 'pickup' ? 0 : this.formdata.delivery_type === 'curbside' ? 0 : 0;
      },
      'formdata.postcode_id': function () {
        store.dispatch('shop/getClosestRetailer', this.formdata.postcode_id);
      },
      companyProfile() {
        this.formdata.company_profile_id = this.companyProfile ? this.companyProfile.id : '';
      },
    },
    created() {
      store.dispatch('shop/getOrderFromLocalStorage', this.area);
      try {
        const storedFormData = JSON.parse(localStorage.getItem('shop.formData'));
        if (storedFormData && storedFormData.area === (this.isCompanyArea ? 'Company' : 'Customer')) {
          this.formdata = storedFormData;
        }
      } catch (error) {
        // Handle error, e.g., localStorage is not supported or data is corrupted
      }

      if (!this.formdata.area) {
        if (this.isCompanyArea) {
          if (this.companyProfile && this.companyProfile.hasOwnProperty('id')) {
            this.formdata.address = this.companyProfile.address;
            this.formdata.city = this.companyProfile.city;
            this.formdata.postcode_id = this.companyProfile.postcode_id;
            this.formdata.postcode = this.companyProfile.postcode;
            this.formdata.company_profile_id = this.companyProfile.id;
          }
        } else if (this.$route.meta.area === 'Customer') {
          this.formdata.name = store.state.main.user.name;
          this.formdata.surname = store.state.main.user.surname;
          this.formdata.email = store.state.main.user.email;
          this.formdata.address = store.state.main.user.address;
          this.formdata.city = store.state.main.user.city;
          this.formdata.postcode_id = store.state.main.user.postcode_id;
          this.formdata.postcode = store.state.main.user.postcode;
          if (this.companyProfile && this.companyProfile.hasOwnProperty('id')) {
            this.formdata.company_profile_id = this.companyProfile.id;
          }
        }
      }
      if (this.postcodes.length === 0) {
        store.dispatch('getPostcodes', this.country);
      }

      if(this.order.hasOwnProperty('id') && this.order.shop_bulk_order_id) {
        if(!store.state.shop.bulkOrders.find(obj => obj.id === this.order.shop_bulk_order_id)) {
          let order = { uuid: Math.random().toString(36).substring(2, 15), paid: false };
          store.commit('shop/setOrder', order);
          store.commit('shop/setOrderProducts', []);
        }
      }

    },
    mounted() {
      if (this.isCompanyArea && this.products.length === 0) {
        store.dispatch('shop/getCompanyShopProducts', this.$route.params.company_id);
      }
    },
    beforeCreate() {
      if (this.$route.params.area !== 'Company') {
        store.dispatch('shop/getCustomerShopProducts');
      }
    },
    methods: {
      storeFormData() {
        this.formdata.area = (this.isCompanyArea ? 'Company' : 'Customer');
        localStorage.setItem('shop.formData', JSON.stringify(this.formdata));
      },
      removeFromCart(orderProduct) {
        store.dispatch('shop/removeFromCart', orderProduct);
      },
      storeOrder() {
        if (this.isCompanyArea && (!this.formdata.address || !this.formdata.city)) {
          this.error = 'delivery';
        } else if (!this.isCompanyArea && (!this.formdata.address || !this.formdata.city || !this.formdata.name || !this.formdata.surname || !this.formdata.email)) {
          this.error = 'delivery';
        } else if ((this.isCompanyArea && (!this.noreturn || !this.percent10)) || !this.legalTerms) {
          this.error = 'conditions';
        } else {
          this.error = '';
        }
        if (this.error === '') {
          this.showSpinner = true;
          this.formdata.uuid = this.order.uuid ?? Math.random().toString(36).substring(2, 15);
          this.formdata.order_products = this.orderProducts;
          this.formdata.total_price = this.totalPrice.price_incl;
          this.formdata.payment_method = this.paymentMethod;
          if (this.isCompanyArea) {
            companyApi.post('/shop/companies/' + this.$route.params.company_id + '/store', this.formdata, {
              notification: false,
              progress: false,
            }).then(response => [this.evaluatePaymentMethod(response.status, response.data.content, response.data.notification)]);
          } else {
            let userId = this.$route.params?.user_id;
            let uri = '/shop/store/users/';
            if (userId) {
              uri = uri + userId;
            }
            companyApi.post(uri, this.formdata, {
              notification: false,
              progress: false,
            }).then(response => [this.evaluatePaymentMethod(response.status, response.data.content, response.data.notification)]);
          }

        }
      },
      evaluatePaymentMethod(status, content, notification) {
        this.showSpinner = false;

        if (status === 200) {
          this.showSpinner = false;
          if (content && content.hasOwnProperty('status') && content.status === 'succeeded' || content.status === 'invoice') {
            this.setOrderPaid(content.status);
          }
          if (content.payment_method === 'stripe') {
            this.$refs.stripePayment.evaluatePaymentIntent(content, notification);
          } else if (content.payment_method === 'postfinance') {
            this.$refs.postfinancePayment.evaluatePaymentIntent(content, notification);
          }
        }
      },
      name(item) {
        return this.products?.find(obj => obj.id === item)?.name;
      },
      description(item) {
        let product = this.products?.find(obj => obj.id === item);
        if (product) {
          if (this.isCompanyArea && product.parent_id) {
            return this.products?.find(obj => obj.id === product.parent_id)?.name;
          } else {
            return product?.dimensions;
          }
        }
      },
      setOrderPaid(status) {
        this.formdata.paid = true;
        let order = { uuid: Math.random().toString(36).substring(2, 15), paid: true };
        if (this.order) {
          order = JSON.parse(JSON.stringify(this.order));
        }
        order.paid = status;
        store.commit('shop/setOrder', order);
        localStorage.removeItem('cart');
        localStorage.removeItem('formData');
      },
      setPaymentFailed() {
        this.showSpinner = false;
        this.formdata.paid = false;
      },
    },
  };
</script>

<style scoped>

</style>
