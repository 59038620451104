<template>
  <div class="panel">
    <div class="panel flex flex-col space-y-2 md:flex-row border-4 border-green-200 rounded-2xl">
      <p class="pr-5 self-center">Admin Aktionen:<br>
      {{ company.name }} - {{ company.id }}
      </p>
      <button class="btn btn-sm btn-secondary ml-1" @click="openAdminForm('bankTransfer')">{{ $t('companyArea.accounting.credit.bankTransfer') }}</button>
      <button class="btn btn-sm btn-secondary ml-1" @click="openAdminForm('correctionBooking')">{{ $t('companyArea.accounting.credit.correctionBooking') }}</button>
      <button class="btn btn-sm btn-secondary ml-1" @click="openAdminForm('gift')">{{ $t('companyArea.accounting.credit.gift') }}</button>
    </div>

    <confirmation-modal v-if="formAdmin.show" @close="formAdmin.show = false">
      <div class="flex justify-center mt-3">
        <div class="flex flex-col w-full justify-center mt-3">
          <div class="relative mt-4">
            <input v-model="formAdmin.amount"
                   type="number"
                   class="pl-12 w-full border border-gray-400"
                   :placeholder="$t('companyArea.accounting.credit.topupSaldo')"
                   @keyup.enter="chargeAdminTransfer"
            >

            <div class="absolute left-2 top-1.5 text-xxs z-10">{{ company.currency }}</div>
          </div>
          <p class="explanation mt-2">
            <span v-if="formAdmin.transferType === 'bankTransfer'">{{ $t('companyArea.accounting.credit.bankTransfer') }}</span>
            <span v-else-if="formAdmin.transferType === 'correctionBooking'">{{ $t('companyArea.accounting.credit.correctionBooking') }}</span>
            <span v-else>{{ $t('companyArea.accounting.credit.gift') }}</span>
          </p>

          <div class="flex justify-center mt-3">
            <button class="btn btn-lg btn-secondary" @click="chargeAdminTransfer">{{ $t('companyArea.accounting.credit.topUp') }}</button>
          </div>
        </div>
      </div>
    </confirmation-modal>
  </div>
</template>
<script>
  import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
  import store from '../../../store/mainStore';
  import PostfinancePayment from './PostfinancePayment.vue';
  import DatatransPayment from './DatatransPayment.vue';

  export default  {
    name: 'CompanyBalance',
    components: { DatatransPayment, PostfinancePayment, ConfirmationModal },
    props: {
      company: {},
      user: {},
    },
    emits: ['charge-with-main-card'],
    data() {
      return {
        showPostfinanceForm: false,
        showDatatransForm: false,
        formAdmin: {
          show: false,
          transferType: '',
          amount: '',
          currency: store.state.company.company.currency,
        },
      }
    },
    methods: {
      chargeAdminTransfer() {
        this.formAdmin.show = false;
        if (this.formAdmin.transferType === 'bankTransfer') {
          store.dispatch('company/topUpBankTransfer', this.formAdmin);
        } else if (this.formAdmin.transferType === 'correctionBooking') {
          store.dispatch('company/correctionBooking', this.formAdmin);
        } else {
          store.dispatch('company/giftTransfer', this.formAdmin);
        }
      },
      charge() {
        if (this.company.default_payment_provider === 'datatrans') {
          this.showDatatransForm = true;
        } else {
          this.showPostfinanceForm = true;
        }
      },
      openAdminForm(type) {
        this.formAdmin.transferType = type;
        this.formAdmin.show = true;
      },
    }
  };
</script>
