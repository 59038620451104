<template>
  <div>
    <textarea
      id="aiText"
      v-model="plainText"
      rows="10"
      style="width: 100%;"
    ></textarea>

    <button @click="saveText" class="btn btn-primary btn-sm">{{ $t('general.save') }}</button>
  </div>
</template>

<script>
  export default {
    props: {
      aiTextJson: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        plainText: '',
      };
    },
    watch: {
      // Überwache die Prop und extrahiere Text ohne HTML
      aiTextJson: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            if (newVal.ai_text) {
              this.plainText = this.stripHtml(newVal.ai_text);
            } else if (newVal.custom_text) {
              this.plainText = this.stripHtml(newVal.custom_text);
            }
          }
        },
      },
    },
    methods: {
      // Entfernt HTML-Tags aus dem Text
      stripHtml(html) {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
      },
      // Fügt <p>-Tags basierend auf Zeilenumbrüchen hinzu
      formatToParagraphs(text) {
        return text
          .split('\n')
          .filter(line => line.trim() !== '') // Leere Zeilen ignorieren
          .map(line => `<p>${line.trim()}</p>`)
          .join('\n');
      },
      // Speichert den Text, indem er zurück ins JSON konvertiert wird
      saveText() {
        const formattedText = this.formatToParagraphs(this.plainText);
        const updatedJson = {
          custom_text: formattedText,
        };
        console.log(formattedText)
        this.$emit('save', updatedJson); // Emitiere den JSON, um ihn zu speichern
      },
    },
  };
</script>

<style>
    textarea {
        margin-bottom: 1rem;
    }
</style>
