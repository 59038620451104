<template>
  <div>
    <confirmation-modal v-if="showForm"
                        theme="white"
                        @close="closeForm"
    >
      <div class="flex justify-center mt-3">
        <div class="flex flex-col w-full justify-center mt-3">
          <!-- Display the Stripe payment form which gets loaded from stripe itself -->
          <form v-if="showStripeForm === true" id="payment-form">
            <div id="payment-element">
              <!--Stripe.js injects the Payment Element Here-->
            </div>
            <div v-if="paymentMethodId === null"><p class="text-xs font-light">(*)</p></div>
            <div class="flex justify-center mt-3">
              <button
                id="submit"
                type="button"
                @click="confirmStripePaymentIntent"
                class="btn btn-lg btn-secondary"
                data-cy="btn-stripe-pay-now">
                <div id="spinner" class="spinner hidden"></div>
                {{ $t('companyArea.accounting.credit.payNow') }}
              </button>

<!--              <button id="submit" @click="confirmStripePaymentIntent">-->
<!--                <div id="spinner" class="spinner hidden" />-->
<!--                <button type="submit" class="btn btn-lg btn-secondary" data-cy="btn-stripe-pay-now">{{ $t('companyArea.accounting.credit.payNow') }}</button>-->
<!--              </button>-->
            </div>
            <div v-if="paymentMethodId === null" class="mt-5">
              <p class="text-xs font-light">
                {{
                  $t('companyArea.accounting.credit.stripeConditionsExplanation')
                }}
              </p>
            </div>

            <div id="payment-message" class="hidden" />
          </form>

          <div v-if="showStripeForm !== true">
            <p class="text-xl font-semibold mb-8">
              {{ paymentMethodId ? $t('companyArea.accounting.credit.topupWithSavedCreditCard') : $t('companyArea.accounting.credit.topupWithCreditCard') }}
            </p>
            <stripe-payment-method-info v-if="paymentMethodId" :payment-method="stripePaymentMethods.find(obj => obj.id === paymentMethodId)" />
            <p :class="formCharge.showMinAmount === false ? 'hidden text-red-700 text-lg' : 'text-red-700 text-lg'">
              {{ $t('companyArea.accounting.credit.automaticRefillMinimum', { currency: company.currency, amount: showStripePaymentIntent.minAmount }) }}
            </p>
            <div class="relative mt-4">
              <input v-model="formCharge.amount"
                     type="number"
                     class="pl-12 w-full border border-gray-400"
                     :placeholder="$t('companyArea.accounting.credit.enterAmount')"
                     data-cy="input-amount"
                     @keyup.enter="charge"
              >

              <div class="absolute left-2 top-1.5 text-xxs z-10">
                {{ company.currency }}
              </div>
            </div>
            <p v-if="formCharge.amount > 0" class="text-center mt-2 text-red-600">
              {{ $t('companyArea.accounting.credit.feesIncluded') }} {{
                (formCharge.amount * 1.029 + 0.30).toFixed(2)
              }}
            </p>
            <div class="flex justify-center mt-3">
              <button class="btn btn-lg btn-secondary" data-cy="btn-stripe-payment-intent-top-up-add" @click="charge">
                {{
                  $t('companyArea.accounting.credit.topUp')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </confirmation-modal>

    <confirmation-modal v-if="transactionSuccessful" @close="closeStripePaymentIntentModalAndRemoveQueryString">
      <div class="flex flex-col w-full">
        <p class="text-xl font-semibold place-self-center">
          <font-awesome-icon :icon="['fas', 'check']" style="color: #4f7a28;" />
          {{ $t('companyArea.accounting.credit.topupSuccessful') }}
        </p>
        <text-button size="lg"
                     class="place-self-center mt-5"
                     :text="$t('general.close')"
                     @click="closeStripePaymentIntentModalAndRemoveQueryString"
        />
      </div>
    </confirmation-modal>

    <confirmation-modal v-if="transactionFailed" @close="closeStripePaymentIntentModalAndRemoveQueryString">
      <div class="flex flex-col w-full">
        <p class="text-xl font-semibold place-self-center">
          <font-awesome-icon :icon="['fass', 'xmark']" style="color: #b51a00;" />
          {{ $t('companyArea.accounting.credit.topupFailed') }}
        </p>
        <p v-if="stripeError" class="text-lg font-semibold place-self-center mt-5 mb-2 text-red-600">{{ stripeError }}</p>
        <div class="flex justify-center">
          <button class="btn btn-lg btn-secondary my-5 w-auto mx-5" data-cy="btn-stripe-payment-intent-failed-close" @click="closeStripePaymentIntentModalAndRemoveQueryString">
            <span>{{ $t('general.close') }}</span>
          </button>
        </div>
      </div>
    </confirmation-modal>

    <spinner v-if="showSpinner" style="z-index: 9999;" />
  </div>
</template>

<script>
  import ConfirmationModal from '../../../snippets/ConfirmationModal.vue';
  import StripePaymentMethodInfo from './../credit/StripePaymentMethodInfo.vue';
  import TextButton from '../../../snippets/TextButton.vue';
  import companyApi from '../../../connections/company';
  import store from '../../../store/mainStore';
  import notification from '../../../connections/notification';
  import Spinner from '../../../snippets/Spinner.vue';

  export default {
    name: 'StripePayment',
    components: { Spinner, ConfirmationModal, StripePaymentMethodInfo, TextButton },
    props: {
      show: { type: Boolean, default: false },
      paymentMethodId: { type: Number, default: null },
      paymentIntentContent: { type: Object, default: null }, // response from server
    },
    emits: ['success', 'failed'],
    data() {
      return {
        showSpinner: false,
        transactionSuccessful: false,
        transactionFailed: false,
        showStripeForm: false,
        errorMessage: '',
        stripeError: '',
        formCharge: {
          amount: '',
          currency: store.state.company.company.currency,
          showMinAmount: false,
          minAmount: 3,
          returnBaseUri: window.location.pathname,
          paymentMethodId: null,
        },
        showStripePaymentIntent: {
          creditTransactionUuid: { type: String, default: null },
          clientSecret: { type: String, default: null },
          stripeObject: { type: Object, default: null },
          stripeElements: { type: Object, default: null },
        },
      };
    },
    computed: {
      showForm: {
        get() {
          return this.show;
        },
        set(value) {
          this.$emit('update:show', value);
          this.formCharge.paymentMethodId = null;
        },
      },
      company() {
        return store.state.company.company;
      },
      stripePaymentMethods() {
        return this.company.stripe_payment_methods;
      },
      mainStripePaymentMethod() {
        if (stripePaymentMethods.length > 0) {
          return stripePaymentMethods.find(obj => obj.main == true);
        } else {
          return null;
        }
      },
    },
    mounted() {
      let stripePI = this.$route.query.payment_intent;
      let stripePIClientSecret = this.$route.query.payment_intent_client_secret;
      let stripePIStatus = this.$route.query.redirect_status;

      if (stripePIStatus) {
        this.showSpinner = false;
        if (stripePIStatus === 'succeeded') {
          this.transactionSuccessful = true;
          companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-stripe/successful', { clientSecret: stripePIClientSecret, paymentIntent: stripePI }, {
            'progress': false,
            'notification': false,
            'axios-retry': { retries: 1 }
          }).then(response => [store.commit('company/setCredit', response.data.content.creditNew), store.commit('company/setStripePaymentMethods', response.data.content.stripe_payment_methods), this.$emit('success')]);
        } else {
          this.transactionFailed = true;
          companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-stripe/failed', { clientSecret: stripePIClientSecret, paymentIntent: stripePI }, {
            'progress': false,
            'notification': false,
            'axios-retry': { retries: 1 }
          }).then(response => [store.commit('company/setStripePaymentMethods', response.data.content.stripe_payment_methods, this.$emit('failed'))]);
        }
      }
    },
    methods: {
      toggleShowForm() {
        this.showForm = !this.showForm;
      },
      closeForm() {
        this.toggleShowForm();
        // this.showForm = false;
        this.showMinAmount = false;
        this.formCharge.showMinAmount = false;
      },
      loadStripeJs() {
        return new Promise((resolve, reject) => {
          try {
            if (!window.Stripe) {
              const script = document.createElement('script');
              script.src = 'https://js.stripe.com/v3/';
              script.async = true;
              script.onload = () => {
                this.initStripe();
                resolve(); // Resolve the promise when initStripe is done
              };
              script.onerror = () => {
                reject(new Error('Failed to load the StripeJs script.'));
              };
              document.head.appendChild(script);
            } else {
              this.initStripe();
              resolve(); // Resolve the promise when initStripe is done
            }
          } catch (error) {
            reject(error);
          }
        });
      },
      initStripe() {
        this.showStripePaymentIntent.stripeObject = Stripe(import.meta.env.VITE_VUE_APP_STRIPE_API_KEY);
      },
      stripePaymentIntentErrorCallback(error) {
        console.log('stripePaymentIntentErrorCallback - error: ', error);
        this.showSpinner = false;
        notification['error'](this.$t('companyArea.accounting.credit.stripeLoadError'));
        this.transactionFailed = true;
        this.showForm = false;
      },
      closeStripePaymentIntentModalAndRemoveQueryString() {
        this.transactionSuccessful = false;
        this.showStripeForm = false;
        this.transactionFailed = false;

        let cleanURL = window.location.protocol + '//' + window.location.host + window.location.pathname;

        // Update the URL without reloading the page
        if (cleanURL !== undefined && cleanURL.length > 0 && cleanURL !== window.location.href) {
          window.history.replaceState({}, document.title, cleanURL);
        }
      },
      charge() {
        if (this.formCharge.amount < this.formCharge.minAmount) {
          this.formCharge.showMinAmount = true;
          return;
        }

        this.showStripeForm = true;
        this.showSpinner = true;
        this.formCharge.paymentMethodId = this.paymentMethodId;

        // Call the backend to prepare the Stripe Payment Intent and get the ClientSecret from Stripe
        // If it's a saved payment method, we can fully charge on the backend and return the result, but not for sure, we have the see on the response
        companyApi.post('/companies/' + this.company.id + '/credit-transactions/top-up-stripe/create-payment-intent', this.formCharge, {
          'progress': true,
          'notification': true,
          'axios-retry': { retries: 0 }
        }).then(response => [this.evaluatePaymentIntent(response.data.content, response.data.notification)])
          .catch(error => this.stripePaymentIntentErrorCallback(error));
      },
      async evaluatePaymentIntent(content, notification) {
        try {
          if (content && content.status) {
            this.showSpinner = false;
            if (content.status === 'succeeded') {
              this.transactionSuccessful = true;
              this.showForm = false;
              store.commit('company/setCredit', content.creditNew);
            } else if (content.status === 'created' || content.status === 'requires_payment_method' || content.status === 'requires_action') {
              this.showForm = true;
              this.showStripeForm = true;
              await this.loadStripeJs();
              this.useStripePaymentIntent(content.clientSecret, content.customerOptions, content.creditTransactionUuid);
            } else {
              this.transactionFailed = true;
              this.stripeError = notification;
              this.showStripeForm = false;
              this.showForm = false;
            }
          } else {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('No return from the Stripe API');
          }
        } catch (error) {
          this.stripePaymentIntentErrorCallback(error);
        }
      },
      useStripePaymentIntent(clientSecret, customerOptions, creditTransactionUuid) {
        //https://stripe.com/docs/payments/link/add-link-elements-integration?link-integration-type=before-payment

        this.showStripePaymentIntent.creditTransactionUuid = creditTransactionUuid;

        //https://stripe.com/docs/elements/appearance-api?platform=web#theme
        const appearance = {
          theme: 'stripe',

          variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
          },
        };

        const loader = 'auto';

        this.showStripePaymentIntent.stripeElements = this.showStripePaymentIntent.stripeObject.elements({ clientSecret, appearance, loader, customerOptions });
        const paymentElement = this.showStripePaymentIntent.stripeElements.create('payment', {
          layout: 'tabs',
          defaultValues: {
            billingDetails: {
              email: this.company.stripe_email,
              name: this.company.name,
            },
          },
        });

        // Wait, until the DOM is updated, so that the paymentElement can be mounted
        this.$nextTick(() => {
          paymentElement.mount('#payment-element');
        });

      },
      async confirmStripePaymentIntent(e) {
        e.preventDefault();
        this.showSpinner = true;

        let elements = this.showStripePaymentIntent.stripeElements;

        const { error } = await this.showStripePaymentIntent.stripeObject.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + window.location.pathname,
            receipt_email: this.company.stripe_email,
          },
        });
        this.showSpinner = false;

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
          notification['error'](error.message);
        } else {
          notification['error'](this.$t('companyArea.accounting.credit.stripeLoadError'));
        }
      },
    },
  };
</script>
