<template>
  <div v-if="!show.details"
       class="text-right text-xs"
       :class="{ 'cursor-pointer':(clickable)}"
       :data-cy="'lead-costs-' + creditTransactions[0].lead_id"
       @click="clickable ? show.details = true : null"
  >
    <span v-if="clickable">{{ $t('companyArea.leads.leadOverview.snippets.leadCost.cost') }}: </span>{{ creditTransactions[0].currency }} {{ calcCosts(creditTransactions) }}
  </div>
  <div v-else class="absolute bg-white inset-0 z-50 flex flex-col rounded overflow-scroll p-4">
    <button class="absolute flex justify-center items-center top-3 right-3 w-7 h-7 border border-black text-black bg-white hover:bg-black hover:text-white rounded-full z-20"
            data-cy="close-lead-menu"
            @click="show.details = false"
    >
      <font-awesome-icon :icon="['fal','times']" />
    </button>

    <table class="w-full mt-6">
      <tbody>
        <tr v-for="transaction in creditTransactions" class="border-b">
          <td class="flex flex-col mt-2 mb-2">
            <span>{{ transaction.transaction_type.translate_key }} {{ transaction.company_price ? transaction.company_price : '' }}</span>
            <span class="text-xs">{{ this.dayjsHelper.showDateTime(transaction.created_at) }}</span>
          </td>
          <td :class="{ 'align-top text-right pt-2':true, 'text-green-500':(transaction.top_up > 0), 'text-red-500':(transaction.top_up === 0) }">
            {{ transaction.amount.toFixed(2) }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="flex flex-col font-semibold mt-2 mb-2">
            <span>{{ $t('companyArea.leads.leadOverview.snippets.leadCost.total') }}</span>
          </td>
          <td class="text-right font-semibold">
            {{ creditTransactions[0].currency }} {{ calcCosts(creditTransactions) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    name: 'LeadCosts',
    props: {
      creditTransactions: Array,
      clickable: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        show: {
          details: false,
        }
      }
    },
    methods: {
      calcCosts(creditTransactions) {
        let result = 0;
          creditTransactions.forEach(item => {
              if (item.top_up === 0) {
                  result += item.amount;
              } else if (item.top_up === 2) {
                  result -= item.amount;
              }
          });
        return result.toFixed(2);
      }
    }
  };
</script>

<style scoped>

</style>
